import React, { useState, useEffect } from 'react'
import { Paper, Grid } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TabPanel, ErrorView, Loader } from '@percent/admin-dashboard/common/components'
import { useQuery, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import defaultLogo from '@percent/admin-dashboard/common/assets/images/default-logo.png'
import { Eligibility } from './components/eligibility/Eligibility'
import { OverviewDetails } from './components/overviewDetails/OverviewDetails'
import { ComplianceDetails } from './components/complianceDetails/ComplianceDetails'
import { History } from './components/history/History'
import styles from './OrganizationsDetail.module.scss'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { Brand } from './components/overviewDetails/brand/Brand'
import { Contacts } from './components/overviewDetails/contacts/Contacts'
import { OfficialDetails } from './components/overviewDetails/officialDetails/OfficialDetails'
import { PaymentDetails } from './components/overviewDetails/paymentDetails/PaymentDetails'
import { Location } from './components/overviewDetails/location/Location'
import { AccountStatusAndTags } from './components/overviewDetails/accountStatusAndTags/AccountStatusAndTags'
import { UnclaimOrganization } from './components/overviewDetails/unclaimOrganization/UnclaimOrganization'
import { MilestonesContainer } from './milestones/MilestonesContainer'
import { ActivityLogTable } from './activityLog/activityLogTable/ActivityLogTable'
import { Avatar, Heading, Spacer, Tabs } from '@percent/lemonade'
import { Donations } from './components/overviewDetails/donations/Donations'

export function OrganizationsDetail({ match }: { match: { params: { id: string } } }) {
  const location = useLocation()
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  const { id } = match.params
  const { adminService, eligibilityService } = useServices()
  const { orgBrandDetailsFeatureFlag, organizationViewFeatureFlag, paymentMethodsReadFeatureFlag } = useFeatureFlag()
  const history = useHistory()

  const [{ data, isLoading, errorMessage }, { refresh }] = useQuery(adminService.getOrganisation, { id })

  const [{ data: paymentMethodData, isLoading: isPaymentMethodLoading, status }, { refresh: paymentMethodRefresh }] =
    useQuery(adminService.getOrganisationPaymentMethod, { id })

  const [
    { data: vettingEventsResults, isLoading: isLoadingVettingEventsResults, errorMessage: errorVettingEventsResults },
    { refresh: refreshVettingEventsResults }
  ] = useQuery(adminService.getVettingEventsResults, { organisationId: id })

  const [{ data: tagsData, isLoading: isTagsLoading, errorMessage: tagsErrorMessage }] = useQuery(
    eligibilityService.getActivityTags,
    {}
  )

  const [
    { isLoading: isActivityEventsLoading, errorMessageOrNull, dataOrNull, totalResults },
    { nextPage, previousPage }
  ] = useQueryList(eligibilityService.getActivityEvents, { organisationId: id })

  useEffect(() => {
    if (location) {
      setValue(`${location.pathname}`)
    }
  }, [location])

  const handleChange = (newValue: string) => {
    setValue(newValue)
    history.push(newValue)
  }

  if (
    isLoading ||
    isLoadingVettingEventsResults ||
    isTagsLoading ||
    isActivityEventsLoading ||
    isPaymentMethodLoading
  ) {
    return <Loader />
  }

  if (errorMessage || errorVettingEventsResults || tagsErrorMessage || errorMessageOrNull) {
    return (
      <ErrorView errorMessage={errorMessageOrNull || errorMessage || errorVettingEventsResults || tagsErrorMessage} />
    )
  }

  return (
    <>
      <Grid container spacing={0} component={Paper} elevation={0} className={styles.container}>
        <Grid item xs={12} className={styles.nonprofitDetail}>
          <Avatar image={data.logo || defaultLogo} alt="organization-logo" size="medium" />
          <Spacer size={4} axis="horizontal" />
          <Heading level="h6">{data.name}</Heading>
        </Grid>
        <Tabs
          activeTab={value}
          onChangeTab={handleChange}
          items={[
            { key: `/organizations/${data.id}`, label: t('typography.overview') },
            {
              key: `/organizations/${data.id}/milestones`,
              label: t('typography.milestones')
            },
            { key: `/organizations/${data.id}/compliance`, label: t('typography.compliance') },
            { key: `/organizations/${data.id}/activity-log`, label: t('typography.activityLog') }
          ]}
        />
      </Grid>
      <TabPanel value={value} index={`/organizations/${data.id}`}>
        {!organizationViewFeatureFlag && orgBrandDetailsFeatureFlag && <Brand organization={data} refresh={refresh} />}
        {!organizationViewFeatureFlag && (
          <div className={styles.overviewTabContainer}>
            <div className={styles.tabItem}>
              <OverviewDetails organization={data} />
            </div>
            {dataOrNull ? <Eligibility organizationName={data.name} tagsData={tagsData} event={dataOrNull[0]} /> : null}
          </div>
        )}
        {organizationViewFeatureFlag && (
          <>
            <OfficialDetails organization={data} refresh={refresh} />
            <AccountStatusAndTags
              tagsData={tagsData}
              event={dataOrNull?.[0]}
              organisationTypes={Object.keys(data?.types)?.filter(type => data?.types?.[type] === 'yes') || []}
            />
            <Donations organisationId={id} />
            {paymentMethodsReadFeatureFlag && (
              <PaymentDetails
                organization={data}
                paymentMethod={status !== 200 ? undefined : paymentMethodData}
                paymentMethodRefresh={paymentMethodRefresh}
              />
            )}
            <Brand organization={data} refresh={refresh} />
            <Contacts organization={data} refresh={refresh} />
            <Location organization={data} refresh={refresh} />
            <UnclaimOrganization organization={data} refresh={refresh} />
          </>
        )}
      </TabPanel>
      <TabPanel value={value} index={`/organizations/${data.id}/milestones`}>
        <MilestonesContainer />
      </TabPanel>
      <TabPanel value={value} index={`/organizations/${data.id}/compliance`}>
        <div className={styles.complianceContainer}>
          <ComplianceDetails vettingEventsResults={vettingEventsResults} />
          <History
            nonprofitName={data.name}
            organisationId={id}
            refreshVettingEventsResults={refreshVettingEventsResults}
            vettingEventsResults={vettingEventsResults}
          />
        </div>
      </TabPanel>
      <TabPanel value={value} index={`/organizations/${data.id}/activity-log`}>
        <ActivityLogTable
          data={dataOrNull}
          errorMessage={errorMessageOrNull}
          isLoading={isActivityEventsLoading}
          totalResults={totalResults}
          tagsData={tagsData}
          nextPage={nextPage}
          previousPage={previousPage}
        />
      </TabPanel>
    </>
  )
}
