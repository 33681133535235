import { useTranslation } from 'react-i18next'
import { capitalize } from 'lodash'

import { Badge, CardDashboard } from '@percent/lemonade'
import { ValidationSubmissionRelationshipsProps } from './ValidationSubmissionRelationships.types'
import styles from './ValidationSubmissionRelationships.module.scss'

export function ValidationSubmissionRelationships({ relationships }: Readonly<ValidationSubmissionRelationshipsProps>) {
  const { t } = useTranslation()

  if (relationships?.length === 0) return null

  return (
    <CardDashboard title={t('typography.relationships')}>
      <div className={styles.relationships}>
        {relationships?.map(({ result, type, relationship }) => (
          <div key={result.id} className={styles.relationshipWrapper}>
            <div className={styles.relationshipDetails}>
              <span>{result.name}</span>
              <Badge variant="informative">
                {t(type === 'branch' ? 'typography.branch' : 'typography.fiscalSponsorship', {
                  relationship: capitalize(relationship)
                })}
              </Badge>
            </div>
            <span>{result.address}</span>
          </div>
        ))}
      </div>
    </CardDashboard>
  )
}
