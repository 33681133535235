import { Organisation, Partner } from '../admin/admin.types'

import { CurrencyCode, Money } from '@percent/utility'

export enum DonationStatus {
  PENDING = 'ACTIVE',
  REQUESTED_PAYMENT = 'REQUESTED_PAYMENT',
  RECEIVED_PAYMENT = 'RECEIVED_PAYMENT',
  DISBURSED = 'DISBURSED',
  CANCELLED = 'CANCELLED',
  SETTLED = 'SETTLED'
}

export type Donation = {
  id: string
  organisationId: string
  partnerId: string
  status: DonationStatus
  amount: {
    amount: number
    currency: string
  }
  createdAt: Date
  updatedAt: Date
  settledAt?: Date
  disbursedAt?: Date
  cancelledAt?: Date
  deletedAt?: Date
  userId: string
  foundationId?: string
  giftAidId: string
  anonymous?: string
  consentedToBeContactedByOrg?: string
  firstName?: string
  lastName?: string
  email?: string
  metadata?: string
  settledAmount?: string
  donationRequestId?: string
  donationSessionId?: string
  paymentIntentId?: string
  type: 'hosted' | 'direct'
  reassignedAt?: Date
  reassignedReason?: string
  reassignedFromOrganisationId?: string
  reassignedBy?: string
}

export type TotalDonationsCountWithTotal = {
  count: number
  converted: Money
}

export type GetDonationListProps = {
  pageSize?: number
  cursor?: string
  status?: string | null
  partnerId?: string | null
  startDate?: string | null
  endDate?: string | null
  query?: string | null
}

export type GetTotalDonationsWithAmountProps = {
  organisationId: string
  currencyCode?: CurrencyCode
}

export type DonationEnhanced = Donation & {
  partner: Partner
  organisation: Organisation
}

export type PostDonationsBatchCsvProps = {
  donationsBatchCSV: File
}

export type DonationsBatchRow = {
  majorAmount: number
  currencyCode: string
  partnerId: string
  organisationId: string
  createdAt?: string
}

export type PostDonationsBatchProps = {
  payload: DonationsBatchRow[]
}

export type PostDonationsBatchResponse = {
  data: { donationsCount: number }
}

export type PostReassignDonationProps = {
  donationId: string
  payload: { reason: string; organisationId: string; reassignmentKind: ReassignmentKind }
}

export enum ReassignmentKind {
  Merge = 'MERGE',
  Unpayable = 'UNPAYABLE'
}
