import { Alpha3Code } from 'i18n-iso-countries'

export enum StatusType {
  Pending = 'pending',
  Failed = 'failed',
  Succeeded = 'succeeded',
  Processed = 'processed'
}

export type ValidationSubmissionStatus =
  | StatusType.Pending
  | StatusType.Processed
  | StatusType.Succeeded
  | StatusType.Failed

export enum ValidationRequestRejectionReasonCodes {
  NotEligible = 'not_eligible',
  IncorrectDocumentation = 'incorrect_documentation',
  NotAttributableToRegistry = 'not_attributable_to_registry',
  Other = 'other'
}

export type Relationship = {
  type: 'branch' | 'fiscal_sponsorship'
  relationship: 'sponsor' | 'sponsored' | 'parent' | 'child'
  result: {
    id: string
    name: string
    address?: string
    type?: string
  }
}

export type ValidationSubmission = {
  id: string
  validationInviteId: string
  configuration: ValidationSubmissionConfiguration
  status: ValidationSubmissionStatus
  organisation?: { id?: string; name?: string; countryCode?: Alpha3Code; relationships?: Relationship[] } | null
  organisationId: string | null
  organisationName: string | null
  validationRequestId: string | null
  validationRequestName: string | null
  validationRequest?: ValidationRequest
  monitoringSubscriptionId: string
  agentVerificationId: string
  eligibilitySubscriptionId: string
  eligibility?: Eligibility | null
  createdAt: string
  agentVerificationFirstName: string
  agentVerificationLastName: string
  agentVerificationEmail: string | null
  validationRequestDocumentId?: string
}

export enum OrganisationTypes {
  Nonprofit = 'nonprofit',
  SocialImpact = 'social_impact',
  Education = 'education'
}

export type ValidationRequest = {
  id: string
  name: string
  acceptedAt: string | null
  rejectedAt: string | null
  organisationTypes: OrganisationTypes[]
  rejectionReasonCode: ValidationRequestRejectionReasonCodes | null
  website: string
  countryCode: Alpha3Code
}

export type ValidationSubmissionDocument = {
  id: string
  validationSubmissionId: string
  url: string
  createdAt: string
}

export type Monitoring = {
  status: string
  results?: {
    complianceStatus: string
    warning?: {
      status: string
    }
    sanction?: {
      status: string
    }
    controversial?: {
      status: string
    }
    commercial?: {
      status: string
    }
    adverseMedia?: {
      status: string
    }
    hateSpeech?: {
      status: string
    }
    registration?: {
      active: string
    }
  }
}

type ActivitySubTag = {
  id: string
  name: string
  description: string
  createdAt: string
  updatedAt: string | null
  tag: {
    id: string
    name: string
    description: string | null
    createdAt: string
    updatedAt: string | null
  }
}

export type Eligibility = {
  status: string
  results: {
    eligibilityStatus: string | null
    confirmedActivitySubTags: ActivitySubTag[] | null
    rejectedActivitySubTags: ActivitySubTag[] | null
  }
}

export enum AgentVerificationStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING_REVIEW = 'pending_review',
  PENDING_USER_VERIFICATION = 'pending_user_verification'
}

export enum AgentVerificationRejectionReasonCodes {
  ValidationRequestFailed = 'validation_request_failed',
  UserVerificationExpired = 'user_verification_expired',
  UserFailedPercentReview = 'user_failed_percent_review',
  Reapply = 'reapply',
  FakeEmailUsed = 'fake_email_used',
  NoOnlinePresence = 'no_online_presence',
  InvalidDocumentation = 'invalid_documentation',
  Other = 'other'
}

type AgentVerification = {
  id: string
  firstName: string
  lastName: string
  createdAt: string
  email: string
  status: AgentVerificationStatus
  rejectionReason?: string | null
  rejectionReasonCode?: AgentVerificationRejectionReasonCodes | null
  validationRequestId?: string
  validationRequest: {
    name?: string
  }
  organisationId?: string
  organisation: {
    name?: string
  }
  title?: string
  language?: string
  configuration?: { name?: string }
  validationSubmission?: {
    id: string | null
  }
}

export type PartnerFields = Record<string, number | string | Array<string | null> | null>
export type Metadata = Record<string, string>

export type SingleValidationSubmission = ValidationSubmission & {
  agentVerificationRejectionReason: string | null
  agentVerificationStatus: string
  configuration: ValidationSubmissionConfiguration & {
    allowedOrganisations: {
      types: OrganisationTypes[]
    }
  }
  validationRequestAcceptedAt: string | null
  validationRequestRejectedAt: string | null
  eligibility?: Eligibility
  monitoring?: Monitoring
  agentVerification?: Omit<AgentVerification, 'createdAt' | 'validationRequest' | 'organisation'>
  partnerFields: PartnerFields
  metadata: Metadata | null
}

type ValidationSubmissionConfiguration = {
  id: string
  name: string | null
}
