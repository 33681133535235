import React from 'react'
import { useTranslation } from 'react-i18next'

import { CardContent, CardDashboard, Loader, Spacer } from '@percent/lemonade'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useQuery } from '@percent/admin-dashboard/common/hooks'
import { ErrorView } from '@percent/admin-dashboard/common/components'
import { formatMoney } from '@percent/utility'

export function Donations({ organisationId }: { organisationId: string }) {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()
  const { donationsService } = useServices()

  const [{ data, isLoading, errorMessage }] = useQuery(donationsService.getTotalDonationsWithAmount, {
    organisationId,
    currencyCode: 'USD'
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <CardDashboard title={t('typography.donations')}>
        {errorMessage ? (
          <ErrorView errorMessage={errorMessage} />
        ) : (
          <>
            <CardContent title={t('typography.totalDonationsCount')} testId="donations-total-count">
              {data.count}
            </CardContent>
            <CardContent title={t('typography.totalDonationsAmount')} testId="donations-total-amount">
              {formatMoney(data.converted, { locale })}
            </CardContent>
          </>
        )}
      </CardDashboard>
      <Spacer size={6} axis="vertical" />
    </>
  )
}
