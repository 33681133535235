import { Box, TableCell, TableRow } from '@material-ui/core'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  DonationMatchRequestsStatusFilter,
  DonationMatchRequestsTableProps
} from '@percent/admin-dashboard/app/donationMatchRequest/donationMatchRequestsList/donationMatchRequestsTable/DonationMatchRequestsTable.types'
import { Table } from '@percent/admin-dashboard/common/components'
import { formatAmountFromMinorUnits } from '@percent/admin-dashboard/common/utility/formatAmount/formatAmount'
import { useCurrencies } from '@percent/admin-dashboard/common/hooks'
import { dayJS } from '@percent/admin-dashboard/common/library/utility/date'
import styles from './DonationMatchRequestTable.module.scss'
import { DonationMatchRequestStatusBadge } from '@percent/admin-dashboard/app/donationMatchRequest/donationMatchRequestStatusBadge/DonationMatchRequestStatusBadge'
import { ButtonText } from '@percent/lemonade'
import { TableSort } from '@percent/admin-dashboard/common/components/table/Table.types'

const cells = [
  { id: 'partner', isSortable: false, props: { width: '20%' } },
  { id: 'donationAmount', isSortable: false, props: { width: '20%' } },
  { id: 'organization', isSortable: false, props: { width: '20%' } },
  { id: 'status', isSortable: false, props: { width: '15%' } },
  { id: 'submitted', isSortable: true, props: { width: '20%' } }
]

const statusMap = new Map<DonationMatchRequestsStatusFilter, string[]>([
  [DonationMatchRequestsStatusFilter.PENDING, ['processing', 'validating']],
  [DonationMatchRequestsStatusFilter.APPROVED, ['approved']],
  [DonationMatchRequestsStatusFilter.REJECTED, ['rejected']]
])

const tabsMap = new Map<string, DonationMatchRequestsStatusFilter>(
  Array.from(statusMap.entries()).map(([key, value]) => [value.join(','), key])
)

const urlMap: Record<DonationMatchRequestsStatusFilter, string> = {
  [DonationMatchRequestsStatusFilter.PENDING]: '/donation-match-requests?status=processing&status=validating',
  [DonationMatchRequestsStatusFilter.APPROVED]: '/donation-match-requests?status=approved',
  [DonationMatchRequestsStatusFilter.REJECTED]: '/donation-match-requests?status=rejected'
}

export function DonationMatchRequestsTable({
  isLoading,
  totalResults,
  data,
  previousPage,
  nextPage,
  queryParams,
  setQueryParams
}: DonationMatchRequestsTableProps) {
  const { t } = useTranslation()
  const { currencyInfo } = useCurrencies()
  const location = useLocation()
  const history = useHistory()

  const [active, setActive] = useState<string>('')

  useEffect(() => {
    if (location) {
      if (!location.search) {
        history.replace(urlMap[DonationMatchRequestsStatusFilter.PENDING])
        setActive(urlMap[DonationMatchRequestsStatusFilter.PENDING])
      } else {
        setActive(`${location.pathname}${location.search}`)
      }
    }
  }, [location, history])

  const items = useMemo(
    () => [
      { key: urlMap[DonationMatchRequestsStatusFilter.PENDING], label: t('table.pending') },
      { key: urlMap[DonationMatchRequestsStatusFilter.APPROVED], label: t('table.approved') },
      { key: urlMap[DonationMatchRequestsStatusFilter.REJECTED], label: t('table.rejected') }
    ],
    [t]
  )

  const onChangeTab = useCallback(
    (newValue: string) => {
      setActive(newValue)
      history.push(newValue)
    },
    [history]
  )

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    const newStatus =
      statusMap.get(newValue as DonationMatchRequestsStatusFilter) ||
      statusMap.get(DonationMatchRequestsStatusFilter.PENDING)
    setQueryParams({ ...queryParams, status: newStatus })
  }

  const handleRequestSort = () =>
    setQueryParams({
      direction: queryParams.direction === 'ASC' ? 'DESC' : 'ASC'
    })

  const matchRequestsStatusValue = tabsMap.get(queryParams.status?.join(','))

  return (
    <Box className={styles.tableWrapper}>
      <Table
        isLoading={isLoading}
        totalResults={totalResults}
        data={data}
        nextPage={nextPage}
        previousPage={previousPage}
        columns={cells}
        orderBy="submitted"
        value={matchRequestsStatusValue ?? 0}
        handleChange={handleTabChange}
        items={items}
        active={active}
        onChangeTab={onChangeTab}
        handleRequestSort={handleRequestSort}
        order={(queryParams.direction?.toLowerCase() as TableSort) ?? 'desc'}
        emptyTableText={t('table.emptyDonationMatchRequests')}
      >
        {data?.map(row => {
          return (
            <TableRow key={row.id} className={styles.tableRow}>
              <TableCell>{row.partner.name}</TableCell>
              <TableCell>
                {formatAmountFromMinorUnits({
                  currencyCode: row.amount.currency,
                  value: row.amount.amount,
                  currencies: currencyInfo
                })}
              </TableCell>
              <TableCell>{row?.organisation?.name ?? row?.organisationDetails?.name}</TableCell>
              <TableCell>
                <DonationMatchRequestStatusBadge status={row.status} />
              </TableCell>
              <TableCell>{dayJS(row.createdAt).format('DD MMM YYYY, HH:mm')}</TableCell>
              <TableCell align="right" style={{ padding: '16px 0' }}>
                <ButtonText onPress={() => history.push(`/donation-match-requests/${row.id}`)}>
                  {t('table.seeDetails')}
                </ButtonText>
              </TableCell>
            </TableRow>
          )
        })}
      </Table>
    </Box>
  )
}
